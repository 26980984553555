//
// Nav styles.
//

//
// Base
//
@mixin nav($itemSelector: li) {
  margin: 0;
  padding: 0;

  > #{$itemSelector} {
    display: block;
    list-style: none;
    margin-top: 0;
  }
}

//
// Inline
//
@mixin nav--inline($itemSelector: li) {
  > #{$itemSelector},
  > #{$itemSelector} > a {
    display: inline-block;
  }

  > #{$itemSelector} {
    margin-top: 0;
    margin-bottom: 0;
  }
}

//
// Stacked
//
@mixin nav--stacked {
  > li,
  > li > a {
    display: block;
  }
}

//
// Dropdown
//

@mixin nav--dropdown {
  > li {
    position: relative;

    &:hover,
    &:focus {
      > ul {
        display: block;
        top: 100%;
        left: 0;
      }
    }
  }

  ul {
    display: none;
    position: absolute;
    @include nav--stacked;
  }
}

@mixin nav-item--toggle($size: vrem(4)) {
  padding-right: $size;
  position: relative;

  > .toggle {
    display: block;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: $size;
    height: $size;
    text-indent: 150%;
    white-space: nowrap;
    background-position: center;
    background-color: transparent;
    transition: transform .15s;

    &[aria-expanded="true"] {
      transform: rotate(180deg);
    }
  }
}

//
// Tabs and Pills
//

@mixin nav--tabs {
  @include clearfix;
  @include nav;
  // padding-top: vrem(6);
  // border-bottom: 2px solid $orange; // Give the tabs something to sit on

  > li {
    margin-bottom: vrem(1);
    margin-top: 0;
    // float: left;
    position: relative;
    text-align: center;
    @include bp(default) {
      float: left;
    }
    // bottom: -2px;
  }

  > li > a {
    position: relative;
    display: block;
    margin-right: vrem(2);
    padding: vrem(1) vrem(3);
    background-color: rgba($orange-light, .25);
    background-color: rgba($gray-lightest, .5);
    color: $gray;
    @include font-size(link--small);
    font-weight: $fw-bold;
    text-decoration: none;
    border-radius: vrem(8);
    transition: all .15s linear;

    &:hover,
    &:active {
      background-color: rgba($orange-light, .5);
      background-color: $gray-lightest;
      transition: none;
      outline: 0;
    }

    &:active {
      background-color: $orange-light;
      background-color: $gray-light;
      color: $white;
    }

    .reversed & {
      background-color: rgba($orange-light, .25);
      &:hover,
      &:active {
        background-color: $orange-light;
      }
    }

    @include bp(small) {
      padding: vrem(2) vrem(4);
    }

    @include bp(large) {
      padding: vrem(2) vrem(3.5);
    }
  }

  > .is-active > a,
  > .is-active > a:hover {
    color: $gray;
    background-color: $gray-lighter;
    cursor: default;
    .reversed & {
      color: $gray;
      background-color: $white;
    }
  }
}

@mixin nav--tabs--stacked {
  border-bottom: none;

  > li {
    margin-bottom: 0;
  }

  > li > a {
    box-shadow:
      1px 1px 1px 0 $white inset,
      -1px 0 0 0 rgba($gray-dark, .2) inset;
    border-right-color: $gray-white;
    margin-left: -2px;

    &:hover {
      border-color: $gray-light $white $gray-light $gray-light;
    }
  }

  > .active > a,
  > .active > a:hover {
    box-shadow: 1px 1px 1px 0 $white inset;
    margin-right: -1px;
    border-right-color: transparent;
    border-bottom-color: #ddd;
  }
}
