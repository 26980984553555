

.node--type-class {
  .block-page-title-block {
    display: inline-block;
    margin: 0;
  }
  .grade-level__title {
    @include font-size(heading--huge);
    color: $gray;
    display: inline-block;
    margin-left: vrem(3);
  }
}


.view-class-rosters {
  table {

  }

  th,
  td {
    vertical-align: middle;
    padding: vrem(1) vrem(2);
    @include bp(small) {
      padding: vrem(1.5) vrem(3);
    }
    @include bp(medium) {
      padding: vrem(2) vrem(4);
    }
  }

  th {
    @include font-size(body--tiny);
    color: $gray-light;
    letter-spacing: .05em;
    text-transform: uppercase;
  }

  tbody {
    tr:nth-child(2n+1) {
      background: $gray-lightest;
    }
  }

  .views-field-title {
    font-weight: $fw-bold;
  }

  .views-field-field-grade-age {
    text-align: center;
  }

  .views-field-created {
    text-align: center;
    display: none;
    visibility: hidden;
    @include bp(medium) {
      display: table-cell;
      visibility: visible;
    }
  }

  td.views-field-created {
    @include font-size(body--micro);
    text-transform: uppercase;
  }

  .views-field-edit-node {
    text-align: center;
    > a {
      display: inline-block;
      background: $white;
      border: 1px solid $gray-lighter;
      border-radius: vrem(4);
      @include font-size(body--tiny);
      font-weight: $fw-bold;
      letter-spacing: .05em;
      padding: vrem(.5) vrem(2) vrem(.5) vrem(6);
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: vrem(1);
        top: 50%;
        transform: translateY(-50%);
        height: vrem(4);
        width: vrem(4);
        background-position: 50% 50%;
        background-size: 150%;
        opacity: .5;
      }

      &:hover {
        border-color: $teal;
        color: $teal-dark;
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

//  Class Settings

.node__content {
  > .field--name-field-passwords-for-students,
  > .field--name-field-grade-age,
  > .field--name-field-class-status,
  > .field--name-field-screen-time-limit {
    margin-bottom: vrem(5);

    .field__label {
      @include font-size(body--tiny);
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    .field__item {
      font-weight: $fw-regular;
    }

    @include bp(large) {
      float: left;
      margin-right: 5%;
      // width: 30%;
    }
  }

  // > .field--name-field-screen-time-limit {
  //   margin-right: 0;
  // }

  .link--button {
    // clear: left;
    float: left;
    margin: 0;
  }
}

