//
// @file
// Box styles.
//
// These styles deal with inner padding and border widths.
//

@mixin box--constrained($width: $w-full) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

@mixin box--gutters($padding-left: $w-gutter, $padding-right: $padding-left) {
  padding-left: $padding-left;
  padding-right: $padding-right;
}

@mixin box--padded($top: vrem(11), $bottom: $top) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin box--bleed($always: false) {
  @include box--gutters(4.5%);

  @if ($always == false) {
    @include bp(xx-large) {
      @include box--gutters(0);
    }
  }
}

@mixin box--spaced() {
  margin-bottom: vrem(6);

  @include bp(medium) {
    margin-bottom: vrem(8);
  }

  @include bp(large) {
    margin-bottom: vrem(12);
  }
}

@mixin box--inset() {
  padding: vrem(6) 30px;

  @include bp(medium) {
    padding: vrem(8) 45px;
  }
}

@mixin box--inset--small() {
  padding: vrem(2) 20px;

  @include bp(medium) {
    padding: vrem(4) 25px;
  }
}

// For use in sidebar.
@mixin box--inset--secondary() {
  padding: vrem(6) 30px;

  @include bp(medium) {
    padding: vrem(8) 45px;
  }

  @include bp(large) {
    padding: vrem(6) 40px;
  }
}

@mixin absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin box--triangles($bgcolor: $purple) {
  position: relative;
  padding-bottom: rem(80px);
  padding-top: rem(60px);
  background-color: $bgcolor;
  // background-image: url(/static/img/triangles.svg);
  background-size: 100% auto;
  background-position: 10%;
  color: $white;

 //
  // Triangles
  //
  &:before {
    display: block;
    content: "";
    position: absolute;
    bottom: -1px;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    background-size: 100%;
    background-position: center bottom;
    background-repeat: repeat-x !important; // Override grunticon
    z-index: 0;

    @include bp(none medium) {
      @include header__triangles--mobile;
    }

    @include bp(xx-large) {
      background-size: 50%;
    }
  }
}

@mixin box--triangles--condensed() {
  padding-bottom: vrem(8);
  padding-top: vrem(6);

  @include bp(medium) {
    padding-bottom: vrem(8);
    padding-top: vrem(6);
  }
}
