//
// @file
// Color variables.
//
@import "../utilities/blend-mode";

//
// @file
// Color variables.
//
@import "../utilities/blend-mode";

//
// Colors
//
$white:             #ffffff;
$black:             #000000;

$teal:             #00cd79;

$yellow:            #ffcc00;
$yellow-dark:       #fde04e;
$yellow-darker:     #fed840;

$orange:            #ff9c00;

$red:               #ff3300;

$purple-darker:     #321d3e;
$purple-dark:       #422753;
$purple:            #533168; // KEY
$purple-light:      #836297;
$purple-lighter:    #ac8fbe;
$purple-lightest:   #ceb8db;
$purple-white:      #eadef1;

$teal-darker:       #005473;
$teal-dark:         #006d87;
$teal:              #00859b; // KEY
$teal-light:        #62c5d0;
$teal-lighter:      #66d3db;
$teal-lightest:     #99ebef;
$teal-white:        #ccfafb;

$orange-dark:         #ff6600;
$orange:              #ff9900; // KEY
$orange-light:        #ffcc00;
$orange-lighter:      #ffff00;

$gray-black:        #000000;
$gray-darker:       #444; // old
$gray-darkest:      #333; // old
$gray-dark:         #343235;
$gray:              #676568; // KEY
$gray-light:        #796d69;
$gray-lighter:      #cdcbce;
$gray-lightest:     #e7e6e7;
$gray-white:        #f3f3f3;

//
// Semantic colors
//
$clr-neutral:      $gray;      // Default nuetral color
$clr-text:         $gray;     // Default body copy color
$clr-link:         $teal;            // Default link color
$clr-heading:      $purple;           // Default accent color
$clr-accent:       $gray;           // Default accent color
$clr-border:       $gray-lightest;    // Default border and required color
$clr-status:       $teal-light;      // Default status color
$clr-warning:      $yellow;            // Default warning color
$clr-error:        $red;             // Default error and required color
$clr-table-border: $clr-border;      // Default table border color
$clr-table-odd:    $white;      // Default table odd row color
$clr-table-even:   $gray-white;           // Default table even row color

//
// Color Schemes
//

$color-schemes: (
  white: (
    background: $white,
  ),
  off-white: (
    background: $gray-lightest,
  ),
);
