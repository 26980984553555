//
// Section styles.
//

.select > h3 {
  @include font-size(heading--small);
  padding: vrem(1) vrem(4);
  font-weight: $fw-black;
  color: $gray-dark;
  border-radius: vrem(4);
  background-color: $gray-lightest;
}

.select-list {
  @include nav;
  background-color: #FFF;
  box-shadow: 0 0 15px rgba($gray-darker, .35);
  border-radius: vrem(4);
  padding-top: vrem(4);
  padding-bottom: vrem(4);

  &:before {
    @include pseudo-box(vrem(4))
  }
}

.select-item > a {
  display: block;
  padding: vrem(1) vrem(4);
  @include font-size(heading--micro);
  color: $gray;
  text-decoration: none;
  font-weight: $fw-regular;

  &:hover {
    background-color: $gray-lightest;
  }
}
