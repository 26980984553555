//
// Basic page layout styles.
//
.content-above {
  @include box--triangles;

  input,
  text-area {
    color: $gray;
  }

  input[type="submit"] {
    color: $white;
  }

  a {
    color: $teal-light;
  }
}

.content-above-inner {
  position: relative;
  @include box--bleed;
}

.content-above.content-above--tall {
  min-height: 75vh;

  &:before {
    background-image: (
      url("/static/img/triangles--white.svg"),
      linear-gradient(rgba($purple, 0), rgba($purple, 1) 33%, $purple 99.9%, white 100%),
      url("/static/img/triangles--purple.svg"),
    );

    background-position: (
      center bottom,
      top center,
      0 0,
    );

    @include bp(xx-large) {
      background-size: (
        33%,
        100%,
        33%
      );
    }
  }
}

[role="main"] {
  margin-top: vrem(10);
}
