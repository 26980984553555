// Search Results
.classes__list {
  @include list--no-bullets;
  margin-bottom: vrem(8);
}

.classes__item {
  display: block;
  margin-top: rem(12px);
}

.classes__link {
  border-radius: rem(30px);
  display: block;
  color: $gray;
  font-size: rem(24px);
  font-weight: $fw-demibold;
  line-height: 1.25em;
  padding: rem(15px) rem(30px);
  text-decoration: none;

  &:hover {
    background-color: $teal-lightest;
    color: $gray;
  }

  &[aria-selected="true"] {
    background-color: $teal;
    display: block;
    color: $white;
  }
}

.classes_submit {
  .no-js & {
    display: none;
  }
}
