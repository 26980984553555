//
// Default tag styles.
//
// The only class selectors allowed in this file are those intended
// for use within the WYSIWYG editor.

* {
  box-sizing: border-box;
}

// Establish Vertical Rhythm.
* html {
  font-size: 100% * $base-font-size / $browser-default-font-size;
}

html {
  font-size: 14px;
  // font-size: $base-font-size;
  font-family: $ff-body;
  font-weight: $fw-light;
  // Override the default line height to allow for smaller baseline increments.
  // @include font-size(body);

  @include breakpoint(min-height 600px) {
    font-size: 2.5vh; // 24px in a 960px high window.
  }

  @include breakpoint(min-height 1200px) {
    font-size: 24px; // 24px in a 960px high window.
  }
}

body {
  color: $clr-text;
}

// Use border-box by default on elements typically used for layout.
div,
section,
header,
footer,
aside,
figure {
  box-sizing: border-box;;
}


//
// Headings
//
h1 {
  @include font-size(heading--huge);
  margin: 0;
  font-family: $ff-heading;
  font-weight: $fw-medium;
  color: inherit;
}

h2 {
  @include font-size(heading--huge);
  margin-top: vrem(8);
  margin-bottom: 0;
  font-family: $ff-heading;
  font-weight: $fw-bold;
  color: $purple;
}

h3 {
  @include font-size(heading--large);
  margin-top: vrem(8);
  margin-bottom: 0;
  font-family: $ff-heading;
  font-weight: $fw-bold;
  color: $purple;
}

h4 {
  @include font-size(heading);
  margin-top: vrem(8);
  font-family: $ff-heading;
  margin-bottom: 0;
  color: $purple;
  font-weight: $fw-bold;
}

h5 {
  @include font-size(heading--small);
  margin-top: vrem(8);
  margin-bottom: 0;
  font-family: $ff-heading;
  font-weight: $fw-bold;
  color: $purple;
}


h6 {
  @include font-size(heading--tiny);
  margin-top: vrem(8);
  margin-bottom: 0;
  color: $purple;
  font-style: normal;
  font-weight: $fw-bold;
}

//
// Links
//
a {
  @include link-colors($teal, $teal-dark);

  h1 > &,
  h2 > &,
  h3 > &,
  h4 > &,
  h5 > &,
  h6 > & {
    text-decoration: none;
  }
}

strong,
b {
  font-weight: $fw-bold;
}


//
// Copy
//
p {
  margin: vrem(2.5) 0;
  @include space-between-headings(12px);

  > a {
    font-weight: $fw-regular;
  }
}

blockquote {
  color: $purple;
  font-style: italic;
  font-weight: $fw-regular;
  margin: vrem(2.5) vrem(5);
  // text-indent: -.4em;
}

hr {
  border: none;
  height: 0;
  border-top: 1px solid $clr-border;
  margin: vrem(8) 0;
}


//
// Images
//
img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;

  img {
    display: block;
  }
}

//
// Lists
//

li {
  margin-top: vrem(5);
}

ul:not([class]) {
  @include list;
  @include list--bullets;
}

ol:not([class]) {
  @include list;
  @include list--numbered;
}

//
// Definition Lists
//
dl {
  margin-bottom: vrem(3);
}

dt {
  font-weight: $fw-bold;
}

dd {
  margin-left: $w-gutter;
}

button {
  text-transform: uppercase;
}

input {
  // height: vrem(5.5);
  // height: vrem(7);
}

input,
textarea {
  font-weight: $fw-regular;
  padding: rem(6px) rem(24px);
  width: 100%;
  max-width: 36em;
  font-size: rem(20px);
  border: 3px solid $clr-border;
  transition: border-color .15s linear;
  box-shadow: none;
  -webkit-appearance: none;

  &:hover {
    border-color: $gray-light;
  }

  &:active {
    border-color: $gray;
  }

  .reversed & {
    border-color: $white;

    &:hover {
      border-color: $orange-light;
    }

    &:active {
      border-color: $orange;
    }
  }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="email"],
input[type="number"] {
  height: rem(48px);
  box-shadow: none;
  border-radius: vrem(1);
}

input[type="checkbox"] {
  height: auto;
}

input[type="submit"],
button {
  @include button;
  @include button--medium;
  @include button--primary;
  width: auto;

  &:disabled {
    opacity: 0.2;
    &:hover {
      background-color: $teal-light;
    }
  }
}

form[id=studentLoginForm] {
  &:focus {
    outline: 0;
  }
}

label {
  display: block;
  font-size: rem(16px);
  font-weight: $fw-bold;
  font-family: $ff-normal;
  margin-bottom: vrem(2);
  text-transform: uppercase;

  .reversed & {
    color: $white;
  }
}

input + label {
  margin-top: rem(36px);
}

select {
  @include font-size(body--small);
  font-weight: $fw-regular;
  appearance: none;
  background: $white;
  text-overflow: '';
  text-indent: 0.01px;
  color: $gray;
  display: block;
  height: vrem(8);
  border-radius: vrem(1);
  // border: 0;
  border: 3px solid $clr-border;
  padding: vrem(1) vrem(10) vrem(1) vrem(4);
  position: relative;
  background-position: right 12px top 50%;
  background-size: 24px;
  transition: border-color .15s linear;
  //&:focus {
  //  border-color: $gray;
  //  outline: none;
  //}
  .reversed & {
    border-color: $white;

    &:hover {
      border-color: $orange-light;
    }

    &:active {
      border-color: $orange;
    }
  }
}

//
// Tables
//
table {
  @include font-size(body--small);
  font-weight: $fw-regular;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  @include space-between-content(24px);
  @include space-between-headings(24px);
  text-align: left;
  margin-top: vrem(12);
  margin-bottom: vrem(12);
}

thead {
  th {
    vertical-align: bottom;
  }

  th,
  td {
    border-bottom: 1px solid $clr-table-border;
  }
}

th,
td {
  padding: 8px;
  vertical-align: top;
}

th {
  font-weight: $fw-bold;
}

// Remove top border from thead by default
caption + thead tr:first-child th,
caption + thead tr:first-child td,
colgroup + thead tr:first-child th,
colgroup + thead tr:first-child td,
thead:first-child tr:first-child th,
thead:first-child tr:first-child td {
  border-top: 0;
}

// Account for multiple tbody instances
tbody + tbody {
  border-top: 2px solid $clr-table-border;
}

tr.odd {
  background-color: $clr-table-odd;
}

tr.even {
  background-color: $clr-table-even;
}

:active {
  outline: 0;
}

:first-child {
  margin-top: 0;
}

:last-child {
  margin-bottom: 0;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
  color: #000;
}

.accessibility {
  max-width: 950px;
  p {
    line-height: 1.5;
  }
  a {
    color: #006C80;
  }
}
