//
// @file
// Breakpoint variables.
//

//
// Typefaces.
//
$ff-mono:  monospace;
$ff-sans:  "Avenir Next W00", helvetica, arial, sans-serif;
$ff-serif: $ff-sans;
$ff-normal: $ff-sans;

//
// Semantic fonts.
//
$ff-body:    $ff-sans;
$ff-heading: $ff-sans;
$ff-display: $ff-sans;

//
// Responsive font-size map.
//
$font-sizes: (
  // Body Copy
  body--micro: (
    default: 12px 13px,
    small: 13px 15px,
    large: 14px 18px
  ),
  body--tiny: (
    default: 12px 15px,
    small: 16px 18px,
    large: 18px 21px
  ),
  body--small: (
    default: 16px 18px,
    small: 18px 21px,
    large: 20px 24px
  ),
  body: (
    default: 24px 30px
  ),
  body--large: (
    default: 20px 24px,
    small: 30px 36px,
    large: 36px 42px
  ),
  body--huge: (
    default: 24px 27px,
    small: 42px 51px,
    large: 60px 66px
  ),
  // Headings
  heading--micro: (
    default: 10px 12px,
    small: 12px 15px,
    large: 18px 21px
  ),
  heading--tiny: (
    default: 15px 18px,
    small: 16px 18px,
    large: 20px 22px
  ),
  heading--small: (
    default: 16px 21px,
    small: 18px 21px,
    large: 24px 30px
  ),
  heading: (
    default: 16px 21px,
    small: 18px 24px,
    large: 36px 42px
  ),
  heading--large: (
    default: 20px 24px,
    small: 30px 36px,
    large: 48px 54px
  ),
  heading--huge: (
    default: 24px 24px,
    small: 42px 51px,
    large: 60px 66px
  ),
  heading--macro: (
    default: 36px 36px,
    small: 60px 60px,
    large: 120px 120px
  ),
  link--micro: (
    default: 10px 12px,
    small: 12px 15px,
    large: 18px 21px
  ),
  link--tiny: (
    default: 10px 12px,
    small: 12px 15px,
    large: 18px 21px
  ),
  link--small: (
    default: 13px 18px,
    small: 16px 18px,
    large: 18px 21px
  ),
  link: (
    default: 16px 24px,
    small: 18px 24px
  ),
  link--large: (
    default: 18px 24px,
    medium: 20px 24px
  ),
  link--huge: (
    default: 20px 36px,
    small: 30px 38px
  ),
  link--macro: (
    default: 20px 24px,
    small: 30px 38px,
    large: 42px 48px
  ),
  //
  // Buttons
  //
  button--tiny: (
    default: 13px 18px,
    small: 14px 18px,
  ),
  button: (
    default: 15px 15px,
    small: 15px 15px,
    large: 16px 18px
  ),
  button--small: (
    default: 14px 21px,
    small: 18px 24px,
    large: 20px 30px
  ),
  input: (
    default: 16px 18px,
    small: 18px 24px,
    large: 20px 30px
  ),
  // Titles
  title--micro: (
    default: 14px 18px,
    small: 18px 24px
  ),
  title--tiny: (
    default: 16px 18px,
    small: 20px 24px
  ),
  title--small: (
    default: 18px 24px,
    small: 24px 30px
  ),
  title: (
    default: 24px 30px,
    small: 36px 42px
  ),
  title--large: (
    default: 27px 30px,
    small: 48px 54px
  ),
  title--huge: (
    default: 30px 33px,
    small: 33px 36px,
    medium: 54px 60px
  ),
  title--macro: (
    default: 33px 36px,
    small: 48px 54px,
    medium: 60px 66px
  ),
  navigation: (
    default: 18px 21px,
    small: 18px 21px,
    medium: 21px 24px,
    large: 24px 30px
  )
);

//
// Font size.
//
$fs-xs:      14px;
$fs-s:       16px;
$fs-m:       18px;
$fs-l:       22px;
$fs-xl:      24px;
$fs-2xl:     30px;
$fs-3xl:     32px;
$fs-4xl:     42px;
$fs-5xl:     48px;

//
// Semantic font size.
//
$fs-h1: $fs-3xl !default;
$fs-h2: $fs-2xl !default;
$fs-h3: $fs-xl !default;
$fs-h4: $fs-m !default;
$fs-h5: $fs-s !default;
$fs-h6: $fs-s !default;

//
// Font Weights
//
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-book: 400;
$fw-medium: 500;
$fw-demibold: 500;
$fw-bold: 700;
$fw-black: 900;

//
// Indentation.
//
$indent-amount: 3em !default;

//
// Vertical rhythm.
//
$base-font-size:    24px;       // Font-size of your body copy.
$base-line-height:  6px;         // Setting the base-line-height half.
                                 // the height of the desired body copy.
                                 // line-height helps prevent sparse.
                                 // leading at different font-sizes.

