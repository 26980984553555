//
// User Navigation component styles.
//

.user-nav {
  // margin-top: vrem(2);
  // padding-bottom: vrem(2);
  position: relative;

  @include bp(medium) {
    // margin-top: vrem(4);
    // padding-bottom: vrem(4);
  }

  &[aria-expanded="true"],
  &:hover {
    .user-nav-heading {
      background-color: $teal;
      // .layout__form & {
      //   background-color: $gray-lightest;
      // }
    }
    .user-nav-content {
      display: block;
    }
  }
  &:focus-within {
    .user-nav-heading {
      background-color: $teal;
      // .layout__form & {
      //   background-color: $gray-lightest;
      // }
    }
    .user-nav-content {
      display: block;
    }
  }
}

.user-nav-heading {
  position: relative;
  height: vrem(8);
  width: vrem(8);
  background-color: $teal;
  background-size: 60%;
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
  text-indent: vrem(10);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: $gray-lighter;
  }

  // .layout__form & {
  //   background-color: $white;

  //   &:hover {
  //     background-color: $gray-lightest;
  //   }
  // }
}

.user-nav-img {
  position: absolute;
  top: 0;
  left: 0;
}

.user-nav-content {
  position: absolute;
  background-color: #FFF;
  border-radius: vrem(3);
  box-shadow: 0 0 15px rgba($gray, .35);
  padding: vrem(3) 0;
  top: 85%;
  top: 100%;
  right: -6px;

  [aria-expanded="false"] > & { display: none; }
  [aria-expanded="true"] > & { display: block; }

  &:before,
  &:after {
    @include pseudo-box(vrem(2));
    position: absolute;
    top: vrem(-1);
    right: vrem(4);
    transform: rotateZ(45deg);
  }

  &:before {
    background-color: transparent;
    box-shadow: 0 0 15px rgba($gray, .35);
    z-index: -1;
  }

  &:after {
    background-color: $white;
    z-index: 1;
  }


  .menu {
    @include nav;
    @include list--no-bullets;
  }

  .menu-item {
    display: block;
    font-weight: $fw-regular;
    padding-top: 0;

    &:before {
      content: none;
    }
  }

  .menu-item > a {
    @include link--dropdown();
  }
}
