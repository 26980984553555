//
// Message styles.
//

//
// Individual messages
//
.messages {
  margin-bottom: vrem(4);
  padding: vrem(1.5) $w-gutter-half vrem(1.5) vrem(8);
  line-height: 1.2;

  a {
    font-weight: bold;
  }

  // TODO: Rework these to .message--error etc.
  &.error    { background-color: $clr-error; }
  &.warning  { background-color: $clr-warning; }
  &.status   { background-color: $clr-status; }
}

//
// Devel overrides
//
.krumo-element,
.krumo-footnote,
.krumo-call,
.krumo-preview {
  color: $black;
}
