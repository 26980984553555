// @mixin space-between($height, $siblings: all self h1 h2 heading .title body title ) {
//   margin-top: $height convert to rems.

//   foreach siblings
//   & + &,
//   h2 + & {
//     margin-top: $height;
//   }
// }

// @mixin space-between(tight, heading);
// @mixin space-between(sparce, body);

@mixin space-between-content($height) {
  margin-top: rem($height);

  &:first-child {
    margin-top: 0;
  }
}

@mixin space-between-headings($height) {
  h1 + &,
  h2 + &,
  h3 + &,
  h4 + &,
  h5 + &,
  h6 + & {
    margin-top: rem($height);
  }
}
