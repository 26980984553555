//
// Heading and title styles.
//

// Page titles
.page__title {
  margin-bottom: vrem(4);
  margin-top: 0;
  font-size: rem(30px);
  font-family: 'Muli', sans-serif;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }
}

.page__title--large {
  font-size: rem(60px);
  font-weight: $fw-bold;
  font-family: 'Muli', sans-serif;

  &:last-child {
    margin-bottom: 0;
  }
}

.page__description {
  margin-bottom: rem(60px);
  max-width: 50em;
  font-size: rem(21px);
  line-height: 1.333em;

  a {
    font-weight: $fw-bold;
    @include link--underline-on-hover;
  }
}
