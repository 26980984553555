
.student__form {
  border-bottom: 1px solid $clr-border;
  border-top: 1px solid $clr-border;
  margin: 0 0 vrem(8) 0;
  padding: vrem(8) 0;
}

.node-student-form {
  @include clearfix;
  position: relative;

  .field--name-title,
  .field--name-field-last-name,
  .field--name-field-grade-age {
    margin-bottom: vrem(5);
    margin-top: 0;
    width: 100%;
    @include bp(small) {
      float: left;
    }

    label {
      @include font-size(body--tiny);
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    .description {
      display: none;
      visibility: hidden;
    }
  }


  .field--name-title {
    @include bp(small) {
      padding-right: 10px;
      width: 50%;
    }
    @include bp(medium) {
      margin-bottom: 0;
      padding-right: 30px;
      width: 30%;
    }
  }

  .field--name-field-last-name {
    @include bp(small) {
      padding-left: 10px;
      width: 50%;
    }
    @include bp(medium) {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 30px;
      width: 30%;
    }
  }

  .field--name-field-grade-age {
    select {
      width: 100%;
    }

    @include bp(small) {
      margin-bottom: 0;
      clear: left;
      padding-right: 20px;
      width: 70%;
    }

    @include bp(medium) {
      clear: none;
      margin-bottom: 0;
      padding-right: 30px;
      width: 25%;
    }
  }

  .form-actions {
    input {
      width: 100%;
    }
    @include bp(small) {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30%;
    }
    @include bp(medium) {
      width: 15%;
    }
  }

}