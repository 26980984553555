//
// List styles.
//

.list--no-bullets,
.list--1up {
  @include list--no-bullets;
}

.list--2up {
  @include list--2up;
}

.list--3up {
  @include list--3up;
}

.list--4up {
  @include list--4up;
}

.list--ruled {
  @include list--ruled(vrem(7));
}

.list--ruled--tight {
  @include list--ruled(vrem(3));
}

.list--links {
  @include list--no-bullets;
  @include list--links;
}

.list--numbered {
  // @include list--no-bullets;
  @include list;
  @include list--numbered;
  margin-top: vrem(3);
}