//
// Header styles
//
header[role="banner"] {
  z-index: 3000;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__site {
  display: flex;
  z-index: 2;
  align-items: center;
  height: 11vh;
  padding: vrem(4);
  margin-right: 10rem;
  width: 33%;

  .no-flexbox & {
    display: inline-block;
    vertical-align: middle;
  }

  @include breakpoint(min-height 1200px) {
    height: 100px;
  }
}

.header__logo {
  // width: vrem(15);
  // width: 12vh;
  transition: all .15s linear;
  align-self: stretch;
  height: 100%;
  width: auto;

  .no-flexbox & {
    display: inline-block;
    vertical-align: middle;
  }

  > img {
    display: block;
    height: 100%;
    width: auto;
  }
}

.header__name {
  padding: vrem(2) 0 0 vrem(3);
  margin-left: vrem(2);
  color: $gray;
  font-size: 2.5vh;
  font-family: 'Muli', sans-serif;
  border-left: #e5e5e5 2px solid;

  .no-flexbox & {
    display: inline-block;
  }

  @include breakpoint(min-height 1200px) {
    font-size: 24px;
  }
}

.header__lang {
  display: flex;
  width: 40%;
  justify-content: flex-end;

  a {
    padding: 0 5px;
    font-size: .8rem;
    font-weight: 700;
    color: #006d87;
  }

  div {
    border-left: 2px solid gray;
    margin: 0 4px;
    opacity: 0.3;
  }

}

.header__utilities {
  padding-right: 1em;
}

// .region-header {
//   // @include box--bleed;
//   // float: right;
//   float: left;
//   position: absolute;
//   left: vrem(15);
//   right: vrem(5);
//   top: 50%;
//   transform: translateY(-50%);
//   padding-left: vrem(1);

//   @include bp(medium) {
//     left: vrem(20);
//     padding-left: vrem(2);
//   }

//   @include bp(large) {
//     left: 120px;
//     padding-left: vrem(3);
//   }

//   @include bp(xx-large) {
//     right: 0;
//   }

//   // position: absolute;
//   // right: 0;
//   // top: 50%;
//   // transform: translateY(-50%);

//   .user-nav {
//     // float: right;
//     // margin-left: vrem(4);
//     // position: absolute;
//     // right: 0;
//     // top: 50%;
//     // transform: translateY(-50%);
//   }

//   .class-nav {
//     // float: right;
//     float: left;
//     position: relative;
//     z-index: 2000;
//   }

//   .block-ltlt-class {
//     clear: left;
//     float: left;
//     // margin-left: vrem(4);
//     // .link--button {
//     //   float: right;
//     //   margin: 0;
//     //   @include font-size(button--small);
//     // }
//   }
// }

.toolbar {
  z-index: 5000;
}
