////
/// Button Mixins
/// @group buttons
////

/// Base Button Styles
@mixin button {
  display: inline-block;
  border: 0;
  color: $white;
  font-family: $ff-normal;
  font-weight: $fw-bold;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .05em;
}

// Button sizes
// ---------------------------------------------------------------------------
@mixin button--tiny {
  padding: vrem(.5) vrem(2);
  border-radius: vrem(.5);
  @include font-size(button--tiny);
  height: vrem(5);

  @include bp(small) {
    padding: vrem(.875) vrem(2);
  }
}

@mixin button--small {
  padding: vrem(1) vrem(2.5);
  border-radius: vrem(1);
  @include font-size(button);
  height: vrem(5);
}

/// Medium
@mixin button--medium {
  padding: vrem(2) vrem(4);
  border-radius: vrem(8);
  @include font-size(button);
}

/// Large
@mixin button--large {
  padding: vrem(1) 18px;
  @include font-size(link--large);
}

/// Primary
@mixin button--primary {
  background-color: #287C86;
  transition: background-color .15s linear;
  color: $white;

  &:hover {
    background-color: $teal;
    color: $white;
  }

  &:active {
  }

  .reversed & {
    &:hover {
      background-color: $white;
      color: #287C86;
    }
  }
}

@mixin button--secondary {
  background-color: $white;
  border: 1px solid $gray-lighter;
  color: $teal;
  transition: background-color .15s linear, border-color .15s linear;

  &:hover {
    background-color: $white;
    border-color: $teal;
    color: $teal;
  }

  &:active {
  }
}

@mixin button--tertiary {
  background-color: $teal;
  transition: background-color .15s linear;
  color: $white;

  &:hover {
    background-color: $teal-darker;
    color: $white;
  }

  &:active {
  }

  .reversed & {
    &:hover {
      background-color: $white;
      color: $teal;
    }
  }
}

// Button icons
// ---------------------------------------------------------------------------
@mixin button-icon($x-size: 18px, $y-size: $x-size) {
  content: '';
  position: relative;
  width: $x-size;
  height: $y-size;
  display: inline-block;
  vertical-align: middle;
  background-position: center;
}

@mixin button-icon--before($x-size: 18px, $y-size: $x-size) {
  vertical-align: middle;

  &:before {
    @include button-icon($x-size, $y-size);
  }
}

@mixin button-icon--after($x-size: 18px, $y-size: $x-size) {
  vertical-align: middle;

  &:after {
    @include button-icon($x-size, $y-size);
  }
}
