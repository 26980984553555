
.roster-list {
  @include list--no-bullets;
  @include clearfix;
  margin-bottom: vrem(10);

  > li {
    margin: 0;

    @include bp(default) {
      float: left;
      width: cp(1, 2);
    }

    @include bp(large) {
      width: cp(1, 3);
    }
  }

  .student__content {

    a {
      @include clearfix;
      display: block;
      position: relative;
      text-decoration: none;
      padding: vrem(3.5);
      border-radius: vrem(50);
      transition: all .15s linear;

      &:hover {
        background-color: $teal-lightest;
      }
    }

    .avatar__image {
      float: left;
      width: cp(68,300);

      > div {
        width: 100%;
        background-color: $teal-light;
        height: 0;
        padding-bottom: 100%;
        width: 100%;
        position: relative;
        background-image: url(/static/img/user.svg);
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: 50%;
      }

      @include bp(medium) {
        width: cp(108,300);
      }
    }

    h3 {
      font-size: 1.5rem;
      font-family: 'Muli', sans-serif;
      margin: 0;
      left: cp(76,300);
      right: 0;
      padding-left: vrem(5);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $gray;
      font-weight: $fw-demibold;

      @include bp(default) {
        font-size: .9rem;
      }

      @include bp(medium) {
        left: cp(108,300);
      }

      @include bp(large) {
        font-size: 1rem;
      }
    }
  }
}


#animatedModal-close {
  color: $white;
  opacity: 0;
  font-weight: bold;
  margin: .25em;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#animatedModal-content {
  color: $white;
  font-weight: bold;
  margin: .25em;
  text-align: center;
  position: absolute;
  top: 40vh;
  font-size: 10vh;
  width: 100%;
}

#studentLoginForm {
  max-width: 90vh;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
}
