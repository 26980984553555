
.thumbnails {
  @include list--no-bullets;
  @include clearfix;
  padding: vrem(4) 0;
  border-top: 1px solid $gray-lighter;
  border-radius: 6px;
  overflow: hidden;


  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  > li {
    float: left;
    height: 0;
    margin: 0;
    margin-right: 5%;
    padding-bottom: 16%;
    position: relative;
    width: 16%;

    &:last-child {
      margin-right: 0;
    }
  }

  .thumbnail {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all .15s linear;
    border: 3px solid rgba($teal-lightest, 0);
    border-radius: 50%;
    background-color: $gray-lightest;

    [data-selection="active"] {
      display: none;
    }

    &.selected {

      &:hover {
        border-color: $teal-lightest;
      }

      [data-selection="active"] {
        display: block;
      }

      [data-selection="inactive"] {
        display: none;
      }
    }

    img {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70%;

      @include bp(medium) {
        width: 50%;
      }
    }

    &:hover {
      border-color: rgba($teal-lightest, .25);
      transition: all .05s linear;
    }
  }
}

.thumbnail.selected {
  [data-input="key1"] & { background-color: $teal-light; }
  [data-input="key2"] & { background-color: $red; }
  [data-input="key3"] & { background-color: $purple; }
}
