//
// @file
// A clearfix mixin.
// ---------------------------------------------------------------------------
@mixin clearfix--legacy {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
