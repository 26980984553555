//
// Password form field styles.
//

//
// Password Validation
//
.password-strength {
  @include font-size(body--tiny);
  margin-top: vrem(2);
  font-family: $ff-sans;
  display: block;
  width: 100%;
  // padding-left: vrem(3);
  // padding-right: vrem(3);

  @include bp(large) {
    // padding-left: vrem(4);
    // padding-right: vrem(4);
  }
}

.password-strength__title {
  font-weight: $fw-regular;
  margin-top: vrem(1);
  display: block;
  // margin-left: vrem(1);
}

.password-strength__text {
  float: right; // LTR
  font-weight: bold;
  @include font-size(body--micro);
  text-transform: uppercase;
}

.password-strength__meter {
  height: vrem(2);
  border-radius: vrem(1);
  background-color: $white;
  background-color: rgba($orange, .5);
}

.password-strength__indicator {
  height: 100%;
  width: 0;
  border-radius: vrem(1);
  background-color: $gray;
  transition: all .15s linear;

  &.is-weak { background-color: $yellow; }
  &.is-fair { background-color: $orange; }
  &.is-good { background-color: blend-overlay(rgba($black, .5), $orange); }
  &.is-strong { background-color: $teal; }
}

input.password-confirm,
input.password-field {
  // width: 16em;
}

// Over-specified becuase the input also has .password-confirm.
div.password-confirm {
  @extend .password-strength;
  font-weight: $fw-regular;
  span {
    @include font-size(body--micro);
    float: right; // LTR
    display: inline-block;
    padding: vrem(.25) vrem(1);
    border-radius: vrem(2);
    text-transform: uppercase;
  }

  .ok {
    background: $teal;
    color: $white;
  }
  .error {
    background: $yellow;
    color: $white;
  }
}

.password-suggestions {
  @include adjust-font-size-to($fs-xs);
  border: 0;
  padding: 0;
  &.description {
    .form-item & {
      font-weight: $fw-bold;
    }
  }

  > ul {
    font-weight: $fw-regular;
    margin-top: vrem(2);

    > li {
      margin-top: vrem(1);
      margin-top: 0;
    }
  }
}

.js-form-type-password-confirm {
  position: relative;

  #edit-pass--description {
    border-top: 1px solid rgba($orange, .5);
    margin-top: vrem(5);
    padding-top: vrem(5);
    @include font-size(body);
    font-weight: $fw-bold;
    display: none;
    visibility: hidden;
  }

  .password-fields {
    margin-top: vrem(3);
    position: relative;
  }

  @include bp(medium) {
    .password-fields {
      padding-right: 50%;
    }
  }

  .form-type-password {
    input {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .password-suggestions {
    background-color: $yellow;
    border-radius: vrem(2);
    color: $white;
    max-width: 100%;
    padding: vrem(3);
    font-weight: $fw-bold;

    @include bp(medium) {
      position: absolute;
      right: 0;
      top: 0;
      // bottom: 0;
      width: 45%;
      // transform: translateY(-50%);
    }

    ul {
      @include list--no-bullets;
      > li {
        font-weight: $fw-regular;
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }
}
