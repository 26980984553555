//
// Footer styles.
//

footer[role="contentinfo"] {
  @include box--constrained;
  padding-bottom: rem(36px);
  padding-top: rem(36px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: $teal-dark;
    font-size: rem(18px);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copyright {
  @include box--bleed;
  color: $gray-light;
  font-size: rem(18px);
}
