//
// Button links
//

.link--go,
.link--button {
  @include button;
  @include button--medium;
  @include button--primary;
  text-decoration: none;
  padding: vrem(1.5) vrem(4);
  margin: vrem(3) 0;
}

.link--block {
  display: block;
}

.link--add {
  @include button;
  @include button--tiny;
  @include button--primary;
  position: relative;
  padding-right: vrem(10);
  text-decoration: none;
  color: $white;
  transition: all .15s linear;

  &:after {
    @include pseudo-box(vrem(5.5));
    background-color: $teal-dark;
    position: absolute;
    right: vrem(1.5);
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background-position: center;
    background-size: 100%;
    transition: all .15s linear;
  }

  &:hover {
    color: $white;
    transition: none;
    &:after {
      transition: none;
      background-color: blend-multiply(rgba($black, .2), $teal-dark);
    }
  }
}

.link--class {
  @include link--dropdown();
}

.link--level {
  @include link--dropdown();
}

.link--edit {
  display: inline-block;
  text-decoration: none;
  width: vrem(5.5);
  height: vrem(5.5);
  background-color: $gray-lightest;
  // position: absolute;
  left: vrem(1.5);
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  background-position: center;
  background-size: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;

  &:hover {
    background-color: $gray-lighter;
    // background-position: $teal-light;
  }
}


.link--back {
  @include button;
  @include button--medium;
  @include button--secondary;
  text-decoration: none;
  padding: vrem(1.5) vrem(4);
}

.link--button--edit {
  padding-left: vrem(9);
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: vrem(1);
    top: vrem(1);
    height: vrem(5.5);
    width: vrem(5.5);
    background-color: $teal-dark;
    background-size: 100%;
    background-position: 50% 50%;
    border-radius: 50%;
    transition: all .15s linear;

    @include bp(small) {
      height: vrem(5.5);
      width: vrem(5.5);
    }

    @include bp(large) {
      height: vrem(6);
      width: vrem(6);
    }
  }

  &:hover {
    &:before {
      background-color: blend-overlay(rgba($black, .25), $teal-dark);
      transition: none;
    }
  }


}
