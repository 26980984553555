//
// Pseudo element helpers.
//
@mixin pseudo-box($width: 30px, $height: $width) {
  content: "";
  display: block;
  width: $width;
  height: $height;
  background-position: center;
}
