//
// Form component.
//
// Ideally we would be sticking to .form-item, .form-item-label and
// .form-item-description, but we need to make some allowances for drupal's
// default markup.
//

.form-item,
.form-actions {
  margin: 0;
}

.form-actions,
.form-item,
.form-wrapper {
  margin-top: vrem(4);

  &:first-child {
    margin-top: 0;
  }
}

.form-item {
  label {
    // color: $yellow;
    // margin-left: vrem(4);
  }
  &.form-type-radio,
  &.form-type-checkbox {
    margin: 0;
    label {
      @include font-size(body--tiny);
      color: mix($gray, $gray-light);
      font-weight: $fw-bold;
      letter-spacing: .05em;
      margin-left: 0;
      text-transform: uppercase;
    }
  }
}

.user-pass > p,
.form-item .description {
  @include font-size(body--tiny);
  margin-top: vrem(2);
  // margin-left: vrem(4);
  font-weight: $fw-regular;
}

.form-item {
  > label + .description {
    margin-bottom: vrem(2);
    margin-top: -(vrem(1));
  }
}


//
// Fieldsets
//
.fieldset-legend {
  font-family: $ff-sans;
  font-weight: bold;
}

.fieldset-description {
  @include adjust-font-size-to($fs-s);
  margin-bottom: rhythm(4, $fs-s);
  font-family: $ff-sans;
}


//
// Autocomplete
//
html.js input.form-autocomplete {
  background-position: 100% 6px;

  &.throbbing {
    background-position: 100% -14px;
  }
}


//
// Checkboxes and Radios
//

// Since these are groups of related form-items, we reduce the spacing a bit.
.form-type-checkboxes .form-type-checkbox,
.form-type-radios .form-type-radio {
  margin: rhythm(1) 0;
}


//
// Errors
//
input.error,
textarea.error,
select.error {
  border: 2px solid red;
}


//
// Machine Names
//
.machine-name-label {
  font-family: $ff-sans;
  font-weight: bold;
}

.machine-name-value {
  font-family: $ff-mono;
  margin: 0 .5em;
}


//
// Required
//
.form-required,
.marker {
  color: $clr-error;
  font-weight: bold;
}

.form-required {
  &:after {
    background-position: 50% 50%;
    background-size: 8px 8px;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    vertical-align: top;
  }
}


//
// Vertical Tabs
//

// These are based on the FAPI vertical tab markup which is different than the
//  nav component.
// @todo See if consolidating these is feasible.
// @todo Consolidate these styles with core's vertical-tabs.css
.vertical-tab-button {
  position: relative;
  font-family: $ff-sans;
}

// Setting the active tab by id assumes only one set of vertical tabs per page.
// @todo Change #active-vertical-tab to .active-vertical-tab
#active-vertical-tab {
  // Removes the ackward box outline on :focus
  top: 0;
}

.form__actions {
  margin-top: rem(24px);
}
