//
// Class Navigation component styles.
//

// Hide the initial state if JS is active to prevent layout thrashing.
.js #block-ltltclasslistblock {
  display: none;
}

.class-nav {
  // margin-top: vrem(2);
  // padding-bottom: vrem(2);
  // max-width: vrem(33);
  position: relative;

  @include bp(medium) {
    // margin-top: vrem(4);
    // padding-bottom: vrem(4);
    max-width: vrem(55);
  }

  @include bp(large) {
    max-width: vrem(80);
  }
}

.class-nav-current {
  position: relative;
  @include font-size(link--small);

  .link--edit {
    position: absolute;
    left: vrem(2);
    left: 0;
    top: 0;
    bottom: auto;
    height: vrem(8);
    width: vrem(8);
    // margin: auto;
    z-index: 1;
    background-color: transparent;
    border-radius: 0;
    z-index: 10;
    background-image: none;

    &:after {
      content: "";
      height: vrem(6);
      width: vrem(6);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $gray-lightest;
      background-position: 50% 50%;
      background-size: 125%;
      border-radius: 50%;
      z-index: -10;
    }

    &:hover {
      &:after {
        background-color: $gray-lighter;
      }
    }

    // .layout__form & {
    //   border-radius: vrem(4);
    //   &:after {
    //     background-color: $white;
    //   }

    //   &:hover:after {
    //     background-color: $gray-lightest;
    //   }
    // }
  }

  .link--class {
    background: none;
    border-radius: vrem(4);
    color: $gray-light;
    font-weight: $fw-black;
    padding: vrem(1) vrem(8) vrem(1) vrem(2);
    float: left;
    z-index: 0;
    // height: vrem(8);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .15s linear;

    &:after {
      border-radius: vrem(4);
      @include pseudo-box(vrem(3.5));
      position: absolute;
      top: 50%;
      right: vrem(3);
      bottom: 0;
      margin: 0;
      background-position: 50% 55%;
      background-size: vrem(2);
      background-color: $gray-lightest;
      transform: translateY(-50%);
      transition: all .15s linear;
    }

    &:hover {
      color: $gray;
      &:after {
        background-color: $gray-lighter;
      }
    }
  }
}

.class-nav-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  border-radius: vrem(3);
  box-shadow: 0 0 15px rgba($gray, .35);
  padding-top: vrem(3);
  top: 85%;
  top: 100%;
  right: 0;
  min-width: 100%;

  .class-nav:hover &,
  .class-nav:focus & {
    display: block;
  }

  &:before,
  &:after {
    @include pseudo-box(vrem(2));
    position: absolute;
    top: vrem(-1);
    right: vrem(4);
    transform: rotateZ(45deg);
  }

  &:before {
    background-color: transparent;
    box-shadow: 0 0 15px rgba($gray, .35);
    z-index: -1;
  }

  &:after {
    background-color: $white;
    z-index: 1;
  }
}

.class-nav-list {
  @include nav;
  @include list--no-bullets;
}

.class-nav-item {
  display: block;
  font-weight: $fw-regular;

  &:before {
    content: none;
  }
}

.class-nav-add.link--add {
  display: block;
  margin: vrem(2);
  min-width: vrem(38);
  white-space: nowrap;
}


.block-ltlt-class-student-login-block {
  clear: left;
  float: left;
  .class-subnav {
    @include list--no-bullets;
    margin-left: vrem(2);
    > li {
      float: left;
      margin: 0;
      padding: 0;
      &:before {
        content: none;
      }

      @include bp(small) {
        margin-right: vrem(4);
      }
      @include bp(large) {
        margin-right: vrem(5);
      }
    }

    a {
      @include font-size(navigation);
      border-radius: vrem(4);
      color: $gray;
      float: left;
      font-weight: $fw-bold;
      padding: vrem(1) vrem(0);
      text-decoration: none;
      transition: all .15s linear;
      position: relative;

      &:after {
        background: $gray;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: all .15s linear;
      }

      &.is-active,
      &:hover {
        color: $yellow;
        &:after {
          background: $yellow;
          height: 3px;
        }
      }
    }
  }
}
