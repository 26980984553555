.layout__form {
  [role="banner"] {
    margin-bottom: vrem(3);

    @include bp(large) {
      margin-bottom: vrem(6);
    }
  }

  [role="main"] {
    max-width: vrem(126);
    margin-top: 0;
  }
}

.path-user {
  .page-title {
    @include font-size(heading--huge);
    margin-bottom: vrem(2);
  }
}
