//
// @file
// Helper functions for doing math.
// ---------------------------------------------------------------------------
@function calc-percent($target, $container) {
  @return ($target / $container) * 100%;
}

@function cp($target, $container) {
  @return calc-percent($target, $container);
}

@function em($target, $context: $base-font-size) {
  @if $target == 0 { @return 0 }
  @return $target / $context + 0em;
}
