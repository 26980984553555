//
// Nav styles.
//

.nav {
  @include nav;
}

.nav-tabs {
  @include nav--tabs;
}

.nav--social {
  @include nav;
  @include nav--inline;

  > li {
    margin-right: 0;

    > a {
      @include link--social;
      width: vrem(3);
      height: vrem(5.75);
      transition: transform .15s;
      background-position: center;
      background-size: 19px;
      margin: 0;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
