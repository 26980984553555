// Search Results
.games__list {
  @include list--no-bullets;
  margin: vrem(16) vrem(5) vrem(8);

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include bp(large) {
    margin-top: vrem(20);
    margin-left: vrem(9);
    margin-right: vrem(9);
  }
}

.games__item {
  display: block;
  display: flex;
  margin: 0 0 vrem(16) 0;
  background-color: $black;

  @include bp(medium) {
    flex-basis: 47%;
  }

  @include bp(xx-large) {
    flex-basis: 30%;
  }
}

.games__link {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $white;
  font-size: rem(18px);
  text-decoration: none;
  line-height: 1.25em;
  font-weight: $fw-bold;
  background-color: $gray;
  // padding-top: 62%;
  // height: 0;
  position: relative;
  background-size: cover;
  transition: opacity .2s ease-out;
  opacity: .85;

  &:hover {
    color: $white;
    opacity: 1;

    .games__link-inner {
      // transform: translateY(-55%) scale(1.1);
    }

  }

  &[aria-selected="true"] {
    background-color: $gray-lighter;
    display: block;
  }

  @include bp(large) {
    font-size: rem(24px);
  }
}

.games__link-inner {
  margin: 0;
  display: block;
  transition: transform .1s ease-in-out;
  background-color: $gray;
  // position: absolute;
  // bottom: 100%;
  // width: 100%;
  padding: vrem(2.5) vrem(4);
  font-family: 'Muli', sans-serif;
}

.games_submit {
  .no-js & {
    display: none;
  }
}

.disclaimer {
  margin-bottom: vrem(10);
  padding: 0 vrem(5); 
  font-size: rem(18px);
  font-style: italic;
  line-height: 1.7;
}
