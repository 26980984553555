
.activity__image {
  margin-bottom: vrem(3);
  a {
    display: block;
  }
  img {
    display: block;
  }
}

.activity__type {
  @include font-size(body--micro);
  color: $gray-light;
  font-weight: $fw-regular;
  letter-spacing: .05em;
  text-transform: uppercase;
}