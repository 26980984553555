
.class-roster-authenticate-form {
  margin: vrem(6) vrem(5) vrem(10);

  .form-item-back-link {
    @include bp(medium) {
      float: right;
    }
  }

  .student__content {
    @include clearfix;
    position: relative;
    @include bp(medium) {
      float: left;
      width: 50%;
    }
  }

  .avatar__image {
    display: block;
    width: 54px;

    > div {
      width: 100%;
    }

    @include bp(medium) {
      float: left;
      margin-right: vrem(2);
      width: 60px;
    }
  }


  .node__title {
    position: absolute;
    top: 50%;
    left: 66px;
    transform: translateY(-50%);
    @include bp(medium) {
      left: 72px;
    }
  }

  .form-item-help {
    @include font-size(body--large);
    clear: both;
    color: $teal;
    text-align: center;
  }


  label {
    letter-spacing: 0;
    margin: 0 0 vrem(5);
    position: relative;
    text-align: center;
    // &:before {
    //   @include pseudo-box(auto, 50%);
    //   position: absolute;
    //   top: .5em;
    //   left: 8%;
    //   right: 8%;
    //   border: 5px solid $clr-border;
    //   border-bottom: 0;
    //   z-index: -1;
    //   margin: auto;
    // }

    // &:after {
    //   @include pseudo-box(8em, 100%);
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   margin: auto;
    //   z-index: -1;
    //   background-color: $white;
    // }
  }

  .button {
    margin: 0;
  }

}
