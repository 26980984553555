//
// @file
// Link styles.
//
// These styles deal with icons and hover effects.
//

@mixin link--underline-on-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin link--icon($width: 30px, $height: $width ) {
  width: $width;
  height: $height;
  background-position: center;
  text-indent: $width * 2;
  overflow: hidden;
  white-space: nowrap;
}

@mixin link--social {
  @include link--icon(24px, 30px);
  margin-right: vrem(1);
}

@mixin link--go($font-size: link--huge) {
  display: block;
  font-family: $ff-serif;
  color: $gray-darker;
  @include font-size($font-size);
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    text-decoration: underline;
    color: $gray-darkest;
  }

  &:after {
    @include link--icon(24px);
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-size: 12px;
    margin-left: .25em;
  }

  @include bp(small) {
    &:after {
      height: 30px;
      width: 30px;
      background-size: auto;
    }
  }
}

@mixin link--arrow($width: .825em, $height: $width * .75) {
  &:after {
    @include pseudo-box($width, $height);
    content: "";
    display: inline-block;
    background-size: 100%;
    background-position: center;
    margin-left: .25em;
    vertical-align: .0625em;
  }
}

/// Black link.
@mixin link--black {
  color: $black;

  &:hover {
    color: $yellow;
  }
}

/// Gray link.
@mixin link--gray {
  color: $gray-dark;

  &:hover {
    color: $gray-dark;
    text-decoration: underline;
  }
}

/// pink link.
@mixin link--pink {
  color: $yellow;

  &:hover {
    color: $yellow;
    text-decoration: underline;
  }
}

/// Green link.
@mixin link--green {
  color: $teal;

  &:hover {
    color: $teal;
    text-decoration: underline;
  }
}

/// yellow link.
@mixin link--yellow {
  color: $yellow;

  &:hover {
    color: $yellow;
    text-decoration: underline;
  }
}

/// White link.
@mixin link--white {
  color: $white;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

@mixin link--dropdown($font-size: link--small) {
  @include font-size($font-size);
  display: block;
  color: $gray;
  text-decoration: none;
  padding: vrem(1.5) vrem(4);
  // height: vrem(8);
  white-space: nowrap;
  position: relative;
  background-color: $white;

  &.active,
  &.is-active,
  &:hover {
    color: $gray;
    background-color: $gray-lightest;
  }

  &.is-active,
  &.active {
    font-weight: $fw-bold;
  }

}
