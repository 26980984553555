//
// Page layout styles.
//

@import "basic";
// @import "home";

// Set the page width.
.l--constrained {
  @include box--constrained;
}

.l--bleed {
  @include box--bleed;
}

// .l--sidebar-after {
//   @include clearfix;
//   // @include box--bleed;

//   > .l-secondary {
//     margin-top: vrem(10);
//   }

//   @include bp(large) {
//     > .l-primary {
//       @include span(15 at 1);
//     }

//     > .l-secondary {
//       @include span(7 at 1 rtl);
//       margin-top: 0;
//     }
//   }

//   @include bp(x-large) {
//     > .l-primary {
//       @include span(16 at 1);
//     }

//     > .l-secondary {
//       @include span(6 at 1 rtl);
//       margin-top: 0;
//     }
//   }

//   @include bp(medium) {
//     > .l-primary:only-child {
//       float: none;
//       width: auto;
//       max-width: 950px;
//       margin-right: auto;
//       margin-left: auto;
//     }
//   }
// }

//
// Drupa 8 layout classes
//
.layout-content {
  @include box--bleed;
  // @include box--triangles;
}

.layout-container {
  margin: 0;
}
