//
// Image styles.
//

//
// Borders
//
.image-frame > img,
.image-frame > a > img {
  border: 1px solid $clr-border;
  box-shadow: 0 1px 3px rgba($gray-dark, .6);
  padding: 4px;
  background-color: $white;
}


//
// Floating
//
.image-left {
  float: left;
  margin: 0 $w-gutter rhythm(1) 0;
}

.image-right {
  float: right;
  margin: 0 0 rhythm(1) $w-gutter;
}

.avatar__image {
  @include clearfix;
  > div {
    float: left;
    border-radius: 50%;
    overflow: hidden;
  }
}