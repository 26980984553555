//
// @file
// List mixins.
// ----------------------------------------------------------------------------

//
// Base
//
@mixin list {
  @include space-between-content(48px);
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;

  > li {
    padding-left: 30px;
    list-style: none;
    position: relative;
    margin-top: vrem(3);

    &:first-child {
      margin-top: 0;
    }

    &:before {
      color: $gray-light;
      display: block;
      position: absolute;
      left: 0;
    }
  }
}

//
// Bullets
//
@mixin list--bullets {
  > li:before {
    content: "\2022";
    font-weight: $fw-bold;
  }

  ul {
    margin-top: vrem(1);
  }
}

//
// Numbered
//
@mixin list--numbered {
  counter-reset: number;

  > li:before {
    font-weight: $fw-bold;
    counter-increment: number;
    content: counters(number, ".") ".";
  }

  ol {
    margin-top: vrem(1);
    padding-left: 30px;

    > li {
      padding-left: 0;
      text-indent: -30px;

      &:before {
        display: inline;
        position: static;
        margin-right: .5em;
      }
    }
  }
}

//
// No bullets
//
@mixin list--no-bullets {
  margin: 0;
  padding: 0;

  > li {
    list-style: none;
  }
}

//
// 2up
//
@mixin list--2up($item-selector: li) {
  $spacer-small: 10;
  $spacer: 16;

  @include list--no-bullets;
  @include clearfix;

  > #{$item-selector} {
    margin-top: vrem($spacer-small);
    vertical-align: top;

    &:first-child {
      margin-top: 0;
    }
  }

  @include bp(medium) {
    text-align: center;

    > #{$item-selector} {
      margin-top: vrem($spacer);
      float: left;
      width: 11/23 * 100%;
      margin-right: 1/23 * 100%;
      text-align: left;
    }
  }

  @include bp(medium) {
    > #{$item-selector} {
      &:nth-child(-n+2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      // Only one item.
      &:last-child:nth-child(2n+1) {
        display: inline-block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }
  }
}

//
// 3up
//
@mixin list--3up($item-selector: li) {
  $spacer-small: 10;
  $spacer: 16;

  @include list--no-bullets;
  @include clearfix;

  > #{$item-selector} {
    margin-top: vrem($spacer-small);
    vertical-align: top;

    &:first-child {
      margin-top: 0;
    }
  }

  @include bp(medium) {
    text-align: center;

    > #{$item-selector} {
      margin-top: vrem($spacer);
      float: left;
      width: 11/23 * 100%;
      margin-right: 1/23 * 100%;
      text-align: left;
    }
  }

  @include bp(medium large) {
    > #{$item-selector} {
      &:nth-child(-n+2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      // Only one item.
      &:last-child:nth-child(2n+1) {
        display: inline-block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }
  }

  @include bp(large) {
    > #{$item-selector} {
      width: span(7 of 23);
      margin-right: span(1 of 23);

      &:nth-child(-n+3) {
        margin-top: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      // Only Two items.
      &:nth-last-child(2):first-child {
        display: inline-block;
        float: none;
      }
      &:nth-last-child(2):first-child ~ li {
        display: inline-block;
        float: none;
        margin-right: 0;
      }
    }
  }
}

//
// 4up
//
@mixin list--4up($item-selector: li) {
  $spacer-small: 10;
  $spacer: 16;

  @include list--no-bullets;
  @include clearfix;

  > #{$item-selector} {
    margin-top: vrem($spacer-small);
    vertical-align: top;

    &:first-child {
      margin-top: 0;
    }
  }

  @include bp(medium) {
    text-align: center;

    > #{$item-selector} {
      margin-top: vrem($spacer);
      float: left;
      width: 11/23 * 100%;
      margin-right: 1/23 * 100%;
      text-align: left;
    }
  }

  @include bp(medium large) {
    > #{$item-selector} {
      &:nth-child(-n+2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      // Only one item.
      &:last-child:nth-child(2n+1) {
        display: inline-block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }
  }

  @include bp(large) {
    > #{$item-selector} {
      width: span(5 of 23);
      margin-right: span(1 of 23);

      &:nth-child(-n+4) {
        margin-top: 0;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(4n+1) {
        clear: both;
      }

      // Only Two items.
      &:nth-last-child(2):first-child {
        display: inline-block;
        float: none;
      }
      &:nth-last-child(2):first-child ~ li {
        display: inline-block;
        float: none;
        margin-right: 0;
      }
    }
  }
}

//
// Ruled List
//
@mixin list--ruled ($spacing: 2em, $style: 'inner', $border-color: $clr-border, $border-size: 1px, $border-style: solid, $child-selector: li) {
  @include list--no-bullets;

  @if ($style == inner or $style == above) {
    > #{$child-selector} {
      margin-top: $spacing;
      padding-top: $spacing;
      border-top: $border-size $border-style $border-color;
    }

    @if ($style == inner) {
      > #{$child-selector}:first-child {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;
      }
    }
    @else {
      > #{$child-selector}:first-child {
        margin-top: 0;
      }
    }
  }

  @if ($style == outer or $style == below) {
    > #{$child-selector} {
      margin-bottom: $spacing;
      padding-bottom: $spacing;
      border-bottom: $border-size $border-style $border-color;
    }

    @if ($style == outer) {
      > #{$child-selector}:first-child {
        padding-top: $spacing;
        border-top: $border-size $border-style $border-color;
      }
    }
    @else {
      > #{$child-selector}:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@mixin list--links {
  @include clearfix;
  > li {
    float: left;
    margin: 0;
    margin-right: vrem(3);
    margin-top: vrem(3);
  }

  .link--button {
    margin: 0;
  }
}